(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

require("./components/setup");

require("./components/reset");

require("./components/ajax");

require("./components/helpers");

require("./components/message");

require("./components/dialogs");

require("./components/dimmer");

require("./components/loader");

require("./components/form");

require("./components/modal");

require("./components/search");

require("./components/calendar");

// bind all components

//import "./components/flash";
$.fn.bindComponents = function () {
    $().bindAjax();
    $().bindCalendarPopup();
    $().bindForm();
    $().bindLoader();
    $().bindModalPopup();
    $().bindUI();
};

// --- GLOBAL INJECTIONS ---

// bind page scroller
$('[data-click="scroll-to-top"]').click(function () {
    $('html, body').stop().animate({ scrollTop: 0 }, 'slow', 'swing');
});

// --- PAGE INJECTIONS ---

$(function () {

    // // init cookie nag popup delay
    // setTimeout(function () {
    //     $('.cookie.nag')
    //         .nag({
    //             key: 'accepts-cookies',
    //             value: true
    //         })
    //     ;
    // }, 1800);

    // remember scroll position
    $(window).on('beforeunload', function () {
        document.cookie = "scroll_position=" + $(window).scrollTop();
    });

    var cs = document.cookie ? document.cookie.split(';') : [];
    var i = 0;

    for (; i < cs.length; i++) {
        var c = cs[i].split('=');

        if (c[0].trim() === "scroll_position") {
            $(window).scrollTop(parseInt(c[1]));
            break;
        }
    }
});

},{"./components/ajax":2,"./components/calendar":3,"./components/dialogs":4,"./components/dimmer":5,"./components/form":6,"./components/helpers":7,"./components/loader":8,"./components/message":9,"./components/modal":10,"./components/reset":11,"./components/search":12,"./components/setup":13}],2:[function(require,module,exports){
'use strict';

// bind ajax success
$.fn.ajaxSuccess = function (response) {

    swal('Hinweis', response || 'Die Daten wurden aktualisiert.', 'info');
};

// bind ajax fail
$.fn.ajaxFail = function (response) {

    swal('Ooppps!', response || 'Leider hat das nicht funktioniert.', 'error');
};

// bind ajax call
$.fn.ajaxCall = function (button, resolve, reject) {

    var target = button.data('target'),
        replacement = button.data('replacement'),
        fade = button.data('fade'),
        toggle = button.data('toggle'),
        pre_toggle = button.data('pre-toggle'),
        loader = button.data('loader'),
        title = button.data('info-title') || 'Hinweis',
        content = button.data('info-content');

    $().btnLoader(button);

    if (pre_toggle) button.toggleClass(pre_toggle);

    if (loader) $(loader).addClass('active');

    $.post(target, function (response) {
        if (response.state) button.find('.ajax-state').html(response.state);

        if (response.redirect) location.href = response.redirect;

        if (replacement) {
            if (!fade) {
                $(replacement).html(response);
                $().bindComponents();
            } else $(fade).transition({
                animation: 'fade out',
                duration: '500',
                onComplete: function onComplete() {
                    $(replacement).html(response);
                    $().bindComponents();
                    $(fade).transition({
                        animation: 'fade in',
                        duration: '500'
                    });
                }
            });
        }

        if (pre_toggle) button.toggleClass(pre_toggle);

        if (toggle) button.toggleClass(toggle);

        if (content) swal(title, content, 'info');
    }).done(function () {
        if (typeof resolve === "function") resolve();
    }).fail(function () {
        $().ajaxFail();

        if (typeof rejec === "function") reject();
    }).always(function () {
        $().btnLoader();

        if (loader) $(loader).removeClass('active');
    });
};

// bind ajax
$.fn.bindAjax = function () {

    $('[data-click="ajax"]').click(function () {

        $().ajaxCall($(this));
    });

    $('[data-click="ajax-confirm"]').click(function () {

        var button = $(this),
            confirm = button.data('confirm'),
            title = button.data('confirm-title'),
            content = button.data('confirm-content');

        swal({
            title: title,
            html: content,
            type: 'question',
            input: confirm ? 'text' : null,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
            showCancelButton: true,
            closeOnConfirm: false,
            inputPlaceholder: 'Aktion bestätigen',
            showLoaderOnConfirm: true,
            inputValidator: function inputValidator(value) {
                return new Promise(function (resolve, reject) {
                    if (value === confirm) resolve();else reject('Sie müssen diese Aktion bestätigen!');
                });
            },
            preConfirm: function preConfirm() {
                return new Promise(function (resolve, reject) {
                    $().ajaxCall(button, resolve, reject);
                });
            }
        });
    });
};

$().bindAjax();

},{}],3:[function(require,module,exports){
'use strict';

// bind calendar popup
$.fn.bindCalendarPopup = function () {

    $('.ui.calendar:not(.icon)').each(function (i, el) {
        var type = $(el).data('type') || 'date',
            format = 'DD.MM.YYYY' + (type === 'datetime' ? ' HH:mm' : ''),
            startCalendar = $(el).data('start-calendar') || null,
            endCalendar = $(el).data('end-calendar') || null;

        if (startCalendar) startCalendar = $('#' + startCalendar);

        if (endCalendar) endCalendar = $('#' + endCalendar);

        $(el).calendar({
            on: 'click',
            type: type,
            ampm: false,
            firstDayOfWeek: 1,
            startCalendar: startCalendar,
            endCalendar: endCalendar,
            formatter: {
                datetime: function datetime(date) {
                    return date ? moment(date).format(format) : null;
                }
            },
            parser: {
                date: function date(text) {
                    return moment(text, format).toDate();
                }
            },
            text: {
                days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                today: 'Heute',
                now: 'Jetzt'
            }
        });
    });
};

$().bindCalendarPopup();

},{}],4:[function(require,module,exports){
'use strict';

// bind dialogs
$.fn.bindDialogs = function () {

    $('[data-click="confirm"]').click(function () {

        var button = $(this),
            target = $(this).data('target'),
            title = button.data('title') || 'Bestätigen',
            text = button.data('text');

        swal({
            title: title,
            text: text,
            type: 'question',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: function preConfirm() {
                return new Promise(function () {
                    if (target) location.href = target;
                });
            }
        });
    });
};

$().bindDialogs();

},{}],5:[function(require,module,exports){
'use strict';

// update dimmer
$.fn.updateDimmer = function ($state) {
    var dimmer = $('.ui.dimmer');
    if (dimmer.length !== 0) if ($state) dimmer.addClass('active');else dimmer.removeClass('active');
};

},{}],6:[function(require,module,exports){
'use strict';

// bind form
$.fn.bindForm = function () {

    // translate return to tab event
    $('.form').on('keypress', function (e) {
        if (e.target.nodeName !== 'TEXTAREA' && e.keyCode === 13) {
            var inputs = $(e.target).parents('form').eq(0).find(':input:visible:not(readonly)'),
                idx = inputs.index(e.target);

            if (idx === inputs.length - 1) {
                inputs[0].select();
            } else {
                inputs[idx + 1].focus();
                inputs[idx + 1].select();
            }

            e.preventDefault();
        }
    });

    // focus first form element
    $('form').find('input,textarea,select').filter('.autofocus', ':visible:first').focus().select();
};

$().bindForm();

},{}],7:[function(require,module,exports){
"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

// check, if passed object is empty
$.fn.isEmpty = function (value) {
    return Boolean(value && (typeof value === "undefined" ? "undefined" : _typeof(value)) === 'object') && !Object.keys(value).length;
};

// copy text to clipboard
$.fn.copyToClipboard = function (element, title, text) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text().trim()).select();
    document.execCommand("copy");
    $temp.remove();
    if (title || text) swal(title, text, 'info');
};

// replace query parameter
$.fn.replaceQueryParam = function (search, key, value) {
    var regex = new RegExp('([?;&])' + key + '[^&;]*[;&]?');
    var query = search.replace(regex, '$1').replace(/&$/, '');
    var url = (query.length > 2 ? query + '&' : '?') + (value ? key + '=' + value : '');

    return url.replace(/[?&]$/, '');
};

},{}],8:[function(require,module,exports){
'use strict';

// bind button loader
$.fn.btnLoader = function (btn) {
    $('.button').removeClass('loading');
    if (btn) btn.addClass('loading');
};

// bind generic loader
$.fn.bindLoader = function () {

    $('[data-click="loading"]').click(function () {
        var dimmer = $(this).find('.ui.dimmer');
        if (dimmer.length !== 0) dimmer.addClass('active');else {
            $().btnLoader();
        }
    });

    $('[data-click="btn-loading"]').click(function () {
        $().btnLoader($(this));
    });

    $('[data-click="icon-loading"]').click(function () {
        if ($(this).attr('href')) {
            var icon = $(this).find('i');
            icon.removeClass($(this).attr('data-icon'));
            icon.addClass('loading circle notched');
        } else swal('Hinweis', 'Diese Funktion wird noch implementiert!', 'warning');
    });
};

$().bindLoader();

},{}],9:[function(require,module,exports){
'use strict';

// bind session message
$.fn.bindSessionMessage = function () {

    $('.ui.session.message').transition({
        animation: 'fade in',
        duration: 1000,
        onComplete: function onComplete() {
            if ($('.ui.session.message .close').length === 0) setTimeout(function () {
                $('.ui.session.message').transition('fade out', '750ms');
            }, 1800);
        }
    });

    $('.ui.session.message .close').on('click', function () {
        $(this).closest('.message').transition('fade out', '750ms');
    });
};

$().bindSessionMessage();

},{}],10:[function(require,module,exports){
'use strict';

// bind modal popup
$.fn.bindModalPopup = function () {

    $('[data-click="modal"]').click(function () {
        var sender = $(this),
            popup = $(sender.data('modal')),
            url = sender.data('url');

        $().resetUI();

        sender.addClass('loading');

        $.get(url, function (data) {
            popup.html(data);
            popup.modal({
                blurring: true,
                transition: 'pulse',
                duration: 400,
                onApprove: function onApprove() {
                    var form = $('.ui.form');

                    if (form) {
                        $.post(form.attr('action'), form.serialize()).done(function (response) {
                            if (response) $().ajaxSuccess(response);
                        }).fail(function () {
                            $().ajaxFail();
                        });
                    }
                }
            }).modal('show');
        }).always(function () {
            sender.removeClass('loading');
            $().bindUI();
        }).fail(function (xhr) {
            $().ajaxFail(xhr.responseText);
        });

        return false;
    });
};

$().bindModalPopup();

},{}],11:[function(require,module,exports){
'use strict';

// reset ui components
$.fn.resetUI = function () {

    // hide all opened dropdowns
    $('.dropdown').dropdown('hide');

    // hide all opened tooltips
    $('.tooltip').popup('hide all');
};

},{}],12:[function(require,module,exports){
'use strict';

// bind search box
$.fn.bindSearch = function () {

    $('[data-click="search"]').keypress(function (e) {
        var value = this.value;

        if (e.which === 13 && value.trim().length >= 3) {
            e.preventDefault();

            $(this).next().removeClass('search').addClass('loading circle notched');

            location.href = $(this).data('url') + '/' + value;
        }
    });
};

$().bindSearch();

},{}],13:[function(require,module,exports){
'use strict';

// setup global ajax headers
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// hide dimmer on ajax complete
$(document).bind('ajaxComplete', function () {
    $().updateDimmer(false);
});

// remove loading button on page show
$(window).bind("pageshow", function () {
    $('.button').removeClass('loading');
});

// bind click events
$.fn.bindClickEvents = function () {

    // bind link injection
    $('[data-href]').on('click', function () {
        location.href = $(this).data('href');
    });

    // bind focus injection
    $('[data-click="focus"]').click(function () {
        var target = $(this).data('target');
        $(target).focus();
    });

    // bind back injection
    $('[data-click="back"]').click(function () {
        parent.history.back();
        return false;
    });

    // bind refresh injection
    $('[data-click="refresh"]').click(function () {
        location.reload();
        return false;
    });
};

// bind common ui components
$.fn.bindUI = function () {

    // bind sidebars
    $('.ui.sidebar').sidebar('attach events', '.toc.item');

    // bind accordions
    $('.ui.accordion').accordion();

    // bind checkboxes
    $('.ui.checkbox').checkbox();

    // bind progress bars
    $('.ui.progress').progress();

    // bind toggle buttons
    // $('.ui.button.toggle')
    //     .state()
    // ;

    // bind dropdowns
    $('.ui.dropdown:not(#filter)').dropdown({
        fullTextSearch: true,
        showOnFocus: false,
        transition: 'slide down',
        message: {
            noResults: 'Keine Suchergebnisse!'
        }
    });

    // bind tooltips
    $('.tooltip').popup({
        inline: false,
        position: 'bottom center',
        variation: 'mini inverted',
        duration: 350,
        exclusive: true,
        transition: 'fade in',
        hoverable: false,
        delay: {
            delay: {
                show: 100,
                hide: 100
            }
        }
    });

    $().bindClickEvents();
};

$().bindUI();

},{}]},{},[1]);
